import React from 'react';
import './App.css';
import {useState} from 'react';
import dayjs from 'dayjs'

function App() {

  const savedInitialStart = sessionStorage.getItem('start');
  const savedInclusive = Boolean(sessionStorage.getItem('inclusive'));
  const savedDaysSelectedString = sessionStorage.getItem('selected');
  const savedDaysSelectedJSON = savedDaysSelectedString ? JSON.parse(savedDaysSelectedString) : new Array(7).fill(true);

  const todayFn = () => dayjs().format("YYYY-MM-DD")
  const tomorrowFn = () => dayjs().add(1, "days").format("YYYY-MM-DD")

  const [startFollowToday, setStartFollowToday] = useState<boolean>(() => null != savedInitialStart && savedInitialStart.startsWith("today"));
  const [start, setStart] = useState<string>(getInitStart(savedInitialStart));
  const [end, setEnd] = useState<string>(getInitEnd());
  const [inclusive, setInclusive] = useState<boolean>(savedInclusive);
  const [difference, setDifference] = useState(calcDiff(start, end, inclusive, savedDaysSelectedJSON));


  const [sun, setSun] = useState<boolean>(savedDaysSelectedJSON[0]);
  const [mon, setMon] = useState<boolean>(savedDaysSelectedJSON[1]);
  const [tue, setTue] = useState<boolean>(savedDaysSelectedJSON[2]);
  const [wed, setWed] = useState<boolean>(savedDaysSelectedJSON[3]);
  const [thu, setThu] = useState<boolean>(savedDaysSelectedJSON[4]);
  const [fri, setFri] = useState<boolean>(savedDaysSelectedJSON[5]);
  const [sat, setSat] = useState<boolean>(savedDaysSelectedJSON[6]);

  const daysSelected : {() : boolean; }[] = [
    () => sun.valueOf(),
    () => mon.valueOf(),
    () => tue.valueOf(),
    () => wed.valueOf(),
    () => thu.valueOf(),
    () => fri.valueOf(),
    () => sat.valueOf()
  ]

  class Constants {
    su;
    mo;
    tu;
    we;
    th;
    fr;
    sa;

    constructor() {
      let dayNames: string[] = this.getLocalDayNames();
      this.su = dayNames[0].at(0);
      this.mo = dayNames[1].at(0);
      this.tu = dayNames[2].at(0);
      this.we = dayNames[3].at(0);
      this.th = dayNames[4].at(0);
      this.fr = dayNames[5].at(0);
      this.sa = dayNames[6].at(0);
    }

    getLocalDayNames() {
      const formatter = new Intl.DateTimeFormat(Intl.Locale.name, { weekday: 'long', timeZone: 'UTC' });
      const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
        const dd = day < 10 ? `0${day}` : day;
        return new Date(`2017-01-${dd}T00:00:00+00:00`);
      });
      return days.map(date => formatter.format(date));
    }
  }

  const constants = new Constants();

  function handleOnVisibility() {
    // console.log("on visibility change..vs:" + document.visibilityState + " .focus:" + document.hasFocus())
    if ("visible" === document.visibilityState) {
      let startValue = sessionStorage.getItem('start');
      if (startValue && startValue.startsWith("today-")) {
        let today = todayFn();
        let savedToday = startValue.split("-")[1]
        // console.log("visibility change to visible...")
        if (today !== savedToday) {
          setStart(todayFn());
        }
      }
    }
  }

  document.addEventListener('visibilitychange', handleOnVisibility);

  function getInitStart(initialStart : string | null) : string {
    if (!initialStart) {
      return todayFn();
    } else if (initialStart.startsWith("today")) {
      return todayFn();
    } else {
      return initialStart;
    }
  }

  function getInitEnd() : string {
    const saved = sessionStorage.getItem('end');
    return saved ? saved : tomorrowFn();
  }

  function isDayIncluded(day: number) : boolean {
    return (day >= 0 && day <= 7) ? daysSelected[day]().valueOf() : false;
  }

  function handleInclusiveChange() {
    const nextInclusive = !inclusive.valueOf();
    setInclusive(nextInclusive)
    sessionStorage.setItem("inclusive", ""+nextInclusive);
    setDifference(calcDiff(start, end, nextInclusive, getCurrentSelectedDays()));
  }

  function handleStartChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value.length !== 10) return;
    setStart(event.target.value);
    sessionStorage.setItem("start", event.target.value);
    //console.log("From: " + event.target.value);
    setStartFollowToday(false);
    if (end) calcDifference(event.target.value, end, getCurrentSelectedDays());
  }

  function handleEndChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.value || event.target.value.length !== 10) return;
    setEnd(event.target.value);
    sessionStorage.setItem("end", event.target.value);
    //console.log("To: " + event.target.value);
    if (start) calcDifference(start, event.target.value, getCurrentSelectedDays());
  }

  function getSelectedDaysOverride(day: number, value: boolean) : boolean[] {
    let currentSelectedDays = getCurrentSelectedDays();
    currentSelectedDays[day] = value;
    return currentSelectedDays;
  }

  function getSelectedDaysCount(selectedDays : boolean[]) : number{
    let count = 0;
    for (let i = 0; i < 7; i++) {
      if (selectedDays[i]) count++;
    }
    return count;
  }

  function handleSundayChange() {
    setSun(!sun)
    calcDifferenceOnDayChange(getSelectedDaysOverride(0, !sun));
  }

  function handleMondayChange() {
    setMon(!mon)
    calcDifferenceOnDayChange(getSelectedDaysOverride(1, !mon));
  }

  function handleTuesdayChange() {
    setTue(!tue)
    calcDifferenceOnDayChange(getSelectedDaysOverride(2, !tue));
  }

  function handleWednesdayChange() {
    setWed(!wed)
    calcDifferenceOnDayChange(getSelectedDaysOverride(3, !wed));
  }

  function handleThursdayChange() {
    setThu(!thu)
    calcDifferenceOnDayChange(getSelectedDaysOverride(4, !thu));
  }

  function handleFridayChange() {
    setFri(!fri)
    calcDifferenceOnDayChange(getSelectedDaysOverride(5, !fri));
  }

  function handleSaturdayChange() {
    //console.log("Day Change: " + event.target.value);
    setSat(!sat)
    calcDifferenceOnDayChange(getSelectedDaysOverride(6, !sat));
  }

  function handleStartFollowTodayChange() {
    setStartFollowToday(!startFollowToday);

    if (!startFollowToday) {
      const todayStr = todayFn();
      setStart(todayStr)
      sessionStorage.setItem("start", "today-" + todayStr);
      if (end) calcDifference(todayStr, end, getCurrentSelectedDays());
    } else {
      sessionStorage.setItem("start", start);
    }
  }

  function handleReset() {
    //console.log("Reset");
    const s = todayFn();
    const e = tomorrowFn();
    setStart(s);
    setEnd(e);
    setMon(true);
    setTue(true);
    setWed(true);
    setThu(true);
    setFri(true);
    setSat(true);
    setSun(true);
    setDifference((inclusive) ? 2 : 1);
    if (startFollowToday) {
      sessionStorage.setItem("start", "today-" + s);
    } else {
      sessionStorage.setItem("start", s);
    }
    sessionStorage.setItem("end", e);
  }

  function getCurrentSelectedDays() : boolean[] {
    let ret : boolean[] = [];
    for (let i = 0; i < 7; i++) {
      ret[i] = isDayIncluded(i);
    }
    return ret;
  }

  function calcDifferenceOnDayChange(selectedDays: boolean[]) {
    if (start && end) calcDifference(start, end, selectedDays);
  }

  function calcDifference(from: string, to: string, selectedDays: boolean[]) {
     setDifference(calcDiff(from, to, inclusive, selectedDays));
     sessionStorage.setItem("selected", JSON.stringify(selectedDays));
  }


  function calcDiff(s: string, e: string, incl: boolean, selectedDays: boolean[]) : number {
    let diff = calcExclusiveDiff(s, e, selectedDays)
    if (incl)
      diff += 1;
    return diff;
  }

  function calcExclusiveDiff(s: string, e: string, selectedDays: boolean[]) : number {
    let mStart = dayjs(s);
    let mEnd = dayjs(e);
    if (mStart.isSame(mEnd)) {
      return 0;
    } else if (mStart.isAfter(mEnd)) {
      [mStart, mEnd] = [mEnd, mStart];
    }
    let selectedDaysCount = getSelectedDaysCount(selectedDays);

    if (selectedDaysCount === 0) {
      return 0;
    } else if (selectedDaysCount === 7) {
      return mEnd.diff(mStart,'days')
    } else {
      // count included days from to end of week.
      let count = 0;
      let startDay = mStart.day();
      let endDay = mEnd.day();
      let thisDay = mStart;
      for (let i = startDay; i < 7 && thisDay.isBefore(mEnd); i++) {
        if (selectedDays[i]) count++;
        thisDay = thisDay.add(1, 'days')
      }
      // count whole weeks * number of included days.
      let wholeWeeks = mEnd.diff(thisDay,'weeks');
      count += (selectedDaysCount * wholeWeeks)
      thisDay = thisDay.add(wholeWeeks, 'weeks')
      // count included days from end to end of week.
      for (let i = 0; i < endDay && thisDay.isBefore(mEnd); i++) {
        if (selectedDays[i]) count++;
        thisDay = thisDay.add(1, 'days')
      }
      return count;
    }
    // for (let i = 0; i < 7; i++) {
    //   console.log("Day " + dayNames[i] + " Included: " + selectedDays[i])
    // }
  }

  return (
    <div className="App">
      <div className="App-top-ad"></div>
      <div className="App-column">
        <div className="d-flex justify-content-center flex-nowrap">
        <div className="container text-start">
          <div className="row">
            <div className="mb-1">From</div>
          </div>
          <div className="row flex-nowrap row-height-normal align-items-end">
            <div className="col pe-0" >
              <input className="form-control me-1" id="start" type="date" value={start} onChange={handleStartChange} required></input>
            </div>
            <div className="col ps-1" >
              <input type="checkbox" className="btn-check" name="startFollowToday" id="startFollowToday" autoComplete="off" checked={startFollowToday} onChange={handleStartFollowTodayChange}></input>
              <label className="btn btn-outline-primary fixed-width-button" htmlFor="startFollowToday">Today</label>
            </div>
          </div>
          <div className="row">
            <p className="mt-3 mb-1">to</p>
          </div>
          <div className="row flex-nowrap row-height-normal align-items-end">
            <div className="col pe-0" >
              <input className="form-control me-1" id="end" type="date" value={end} onChange={handleEndChange} required></input>
            </div>
            <div className="col ps-1">
              <button type="button" className="btn btn-info fixed-width-button" id="inclusive" onClick={handleInclusiveChange}>{inclusive ? 'Inclusive' : 'Exclusive'}</button>
            </div>
          </div>
          <div className="row">
          <p className="mt-3 mb-1">counting</p>
          </div>
          <div className="row row-height-normal" >
            <div className="col">
              <div id="days" className="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="checkbox" className="btn-check" name="mon" id="mon" autoComplete="off" checked={mon} onChange={handleMondayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="mon">{constants.mo}</label>
                <input type="checkbox" className="btn-check" name="tue" id="tue" autoComplete="off" checked={tue} onChange={handleTuesdayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="tue">{constants.tu}</label>
                <input type="checkbox" className="btn-check" name="wed" id="wed" autoComplete="off" checked={wed} onChange={handleWednesdayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="wed">{constants.we}</label>
                <input type="checkbox" className="btn-check" name="thu" id="thu" autoComplete="off" checked={thu} onChange={handleThursdayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="thu">{constants.th}</label>
                <input type="checkbox" className="btn-check" name="fri" id="fri" autoComplete="off" checked={fri} onChange={handleFridayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="fri">{constants.fr}</label>
                <input type="checkbox" className="btn-check" name="sat" id="sat" autoComplete="off" checked={sat} onChange={handleSaturdayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="sat">{constants.sa}</label>
                <input type="checkbox" className="btn-check" name="sun" id="sun" autoComplete="off" checked={sun} onChange={handleSundayChange}></input>
                <label className="btn btn-outline-primary" htmlFor="sun">{constants.su}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="mt-3 mb-0">is</p>
          </div>
          <div className="row text-center" >
            <h1 className="text-uppercase fw-bold text-result-colour">{difference} {difference === 1 ? 'day' : 'days'}</h1>
          </div>
        <div className="row mt-5">
          <div className="text-center">
          <button type="button" className="btn btn-info fixed-width-button" onClick={handleReset}>Reset</button>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div className="App-bottom-ad"></div>
    </div>
  );
}

export default App;
